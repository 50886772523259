<template>
  <div style="background-color: #fff">
    <!-- top -->
    <div style="height: 269px; width: 100%">
      <div class="goback flex-space-between">
        <div class="leftbox flex">
          <div class="imgbox"><img src="@/assets/page/fangz.png" alt="" /></div>
          <div class="title">板材</div>
          <div style="margin: 0 4px">-</div>
          <div class="series">{{ listInfo.series.name }}</div>
          <div style="margin: 0 4px">-</div>
          <div class="name">{{ listInfo.title }}</div>
        </div>
        <div class="rightbox flex" @click="gobackclick">
          <div class="fhbox">返回</div>
          <div class="imgbox"><img src="@/assets/page/fh.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- buttombox -->
    <div class="image_box">
      <div class="left_imgbox">
        <!--  border-left: 3px solid #ECECEE; -->
        <div
          v-for="(item, index) in listInfo.images"
          :key="index"
          :class="activeindex == index ? 'activeclass imgitem' : 'imgitem'"
          @click="itemclick(index)"
        >
          <img :src="item" alt=""/>
        </div>
      </div>
      <div class="cosntimg" v-if="listInfo.images">
        <img :src="listInfo.images[activeindex]" alt=""
        />
      </div>
      <div class="textbox">
        <div class="series">{{ listInfo.series.name }}</div>
        <div class="describe">{{ listInfo.en_title }}</div>
        <div class="choice">{{ listInfo.title }}</div>
        <div class="guoji">级别：{{ listInfo.level }}</div>
        <div class="model">
          <div>型号：</div>
          <div>
            <div class="model_item" v-for="(item, index) in listInfo.sizes" :key="index">{{ item .name}}</div>
          </div>
        </div>

        <div class="model">
          <div>规格：</div>
          <div>
            <div class="model_item" v-for="(item, index) in listInfo.specs" :key="index">{{ item .name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 1px; background-color: #d2d2d7; margin-top: 83px"></div>
    <!-- 产品信息 -->
    <div class="Product">
      <div class="Productinfo">产品信息</div>
      <div class="fuProduct">为您呈现最新最流行的花色应用</div>
    </div>
    <div v-html="listInfo.detail" style="margin: 10px 260px;">
     
    </div>
  </div>
</template>
<script>
import {panelCaseDetail} from '../../service/request/homepage';
export default {
  data() {
    return {
      id:null,
      imgarr:[
        {
          url:'https://img2.baidu.com/it/u=1463995935,4142925707&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=725'
        },
        {
          url:'https://img0.baidu.com/it/u=3558164959,1205266526&fm=253&fmt=auto&app=138&f=JPEG?w=626&h=500'
        },
        {
          url:'https://img0.baidu.com/it/u=2064784900,1207716096&fm=253&fmt=auto&app=138&f=JPEG?w=913&h=500'
        },
        {
          url:'https://img2.baidu.com/it/u=1463995935,4142925707&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=725'
        },
        {
          url:'https://img2.baidu.com/it/u=1463995935,4142925707&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=725'
        },
      ],
      activeindex: 0, //点击高亮
      timer: null, //定时器名称
      listInfo:{}//页面数据
    }
  },
  components: {},
 
  mounted() {
    this.id=this.$route.query.id
    this.panelCaseDetail()
   
    // 每隔5分钟定时刷新
    this.timer = setInterval(() => {
      this.getFxItemlist();
    }, 5000)
  },
  // 关闭定时器
  beforeDestroy() {
   clearInterval(this.timer);
 },
  methods: {
    // 初始数据
    panelCaseDetail(){
      panelCaseDetail({id:this.id}).then(res => {
        console.log(res, '11');
        this.listInfo=res.msg
        
      })
    },
    // 定时器方法
    getFxItemlist() {
      
      if (this.activeindex == this.listInfo.images.length-1) {
          this.activeindex=0
      } else if (this.activeindex < this.listInfo.images.length-1) {
        this.activeindex++
      }
    },
    // 返回
    gobackclick() {
      this.$router.go(-1)
    },
    //高亮
    itemclick(index) {
      this.activeindex = index
    }
  }
}
</script>
<style scoped lang="less">
.goback {
  // position: relative;
  padding-top: 188px;
  margin: 0 85px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftbox {
    .imgbox {
      width: 19px;
      height: 16px;
      line-height: 10px;
      margin-right: 9px;
      img {
        width: 19px;
        height: 16px;
      }
    }
    .title,
    .series {
      font-size: 16px;
      color: #999999;
    }
    .name {
      color: #3e907c;
      font-size: 16px;
    }
  }
  .rightbox {
    .fhbox {
      font-size: 16px;
      color: #3e907c;
      margin-right: 6px;
    }
    .imgbox {
      width: 20px;
      height: 14px;
      line-height: 10px;
      img {
        width: 20px;
        height: 14px;
      }
    }
  }
}
.image_box {
  margin: 0 85px;
  display: flex;
  .left_imgbox {
    .imgitem {
    
      width: 130px;
      height: 130px;
      margin-top: 20px;
      padding-left: 13px;
      
      border-left: 3px solid #fff;
      
      img {
        width: 130px;
        height: 130px;
        cursor: pointer;
        border-radius: 8px;
      }
    }
    .activeclass {
      border-left: 3px solid #ececee;
    }
    .imgitem:nth-child(1) {
      margin-top: 0;
    }
  }
  .cosntimg {
    width: 730px;
    height: 730px;
    margin-left: 40px;
   
    img {
      width: 730px;
      
      height: 730px;
      border-radius: 10px;
    }
  }
  .textbox {
    margin-left: 58px;
    .series {
      margin-top: 13px;
      font-size: 24px;
      color: #999999;
    }
    .describe {
      margin-top: 24px;
      font-size: 35px;
      font-weight: 900;
      color: #000;
    }
    .choice {
      margin-top: 30px;
      color: #000000;
      font-size: 35px;
      font-weight: 900;
    }
    .guoji {
      margin-top: 44px;
      font-size: 20px;
      color: #666666;
    }
    .xinghao {
      margin-top: 20px;
      font-size: 20px;
      color: #666666;
    }
    .model {
      margin-top: 20px;
      color: #666666;
      font-size: 20px;
      display: flex;
      .model_item {
        margin-top: 20px;
      }
      .model_item:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
.Product {
  width: 300px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  .Productinfo {
    margin-top: 60px;
    color: #000000;
    font-size: 40px;
  }
  .fuProduct {
    margin-top: 29px;
    font-size: 16px;
    color: #999999;
    margin-bottom: 61px;
  }
}
</style>
